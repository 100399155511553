import React, {Suspense, lazy} from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import "./style/index.css"
import Loader from './components/Loader';
const Home = lazy(() => import("./pages/Home"))
const About = lazy(() => import("./pages/About"))
const Solutions = lazy(() => import("./pages/Solutions"))
const Career = lazy(() => import("./pages/Career"))
const Contactus = lazy(() => import("./pages/Contactus"))
const ViewCareer = lazy(() => import("./pages/ViewCareer"))
const Covid = lazy(() => import("./pages/Covid"))

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

function App() {
  return (
    <BrowserRouter basename={getBasename()}>
      <Suspense fallback={<Loader/>}>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/solutions/:slug" component={Solutions}/>
        <Route exact path="/careers" component={Career}/>
        <Route exact path="/careers/:slug" component={ViewCareer}/>
        <Route exact path="/contact-us" component={Contactus}/>
        <Route exact path="/covid19" component={Covid}/>
      </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
