import React from 'react';
import { Spinner } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';

const Loader = (props) => {
    return(
        <Container fluid style={{backgroundColor:"#011E3A"}}>
            <Row>
                <Col>
                <div class="loader">
                    <Spinner style={{ width: '5rem', height: '5rem', color:"#FE8802" }}  type="grow" color="" />
                </div>
                </Col>
            </Row>
        
        </Container>
    )
}
export default Loader